import React, { useRef, useState, useEffect } from 'react';
import './style.css';
/**
 * Strings
 */
import locates from '../../assets/locates/es.json';
/*
* Reducers
*/
import { useSelector, useDispatch } from 'react-redux';
import { set } from '../../redux/product';
/**
* MUI
*/
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
/**
 * Components
 */
import CustomHeader from '../../components/custom-header';
/**
 * Router
 */
import { useNavigate } from "react-router-dom";

function SelectProduct() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const products = useSelector((state) => state.products.value);

    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [product, setProduct] = useState(null);
    const [productQuantity, setProductQuantity] = useState(1);
    const [toggleBackdrop, setToggleBackdrop] = useState(false);

    //Header div
    const divHeader = useRef();

    function handleDrawerClose() {
        setProductQuantity(1);
        setDrawerIsOpen(false);
    };
    function handleClose() {
        navigate("/");
    };
    function handleAddOrRem(type) {
        if (type === "add") {
            if (productQuantity < 20) {
                setProductQuantity(productQuantity + 1);
            }
        } else {
            if (productQuantity !== 1) {
                setProductQuantity(productQuantity - 1);
            }
        }
    };

    function handleSetProduct(event) {
        event.preventDefault();
        dispatch(set({...product, cant:productQuantity}));
        setToggleBackdrop(true);
        setTimeout(() => {
            setToggleBackdrop(false);
            navigate("/modality");
          }, 1000);
    };


    /**
 * UseEffect
 */
    useEffect(() => {
        if (products === null) {
            navigate("/");
        };
    }, []);


    return (
        <div className="select-products-container">
            <header ref={divHeader}>
                <CustomHeader
                    handleClose={handleClose}
                    noButtons={true}
                />
            </header>
            <div className="select-product-container">
                <Box>
                    <div className="select-product-title-container">
                        <h2 className="xa _a md:ec">{locates.select_a_product}</h2>
                        <p className="ya bb">{locates.select_a_produts_subtitle}</p>
                    </div>
                    <div className="cards-container">
                        <div className="cards">
                            {products ? products.map((row, i) => {
                                return (
                                    <div className="bd-grid" key={row.id} onClick={_ => { setDrawerIsOpen(true); setProduct(row); }}>
                                        <article className="card">
                                            <div className="card__img">
                                                <img src={row.img} alt="" />
                                            </div>
                                            <div className="card__price">
                                                <div className="">
                                                    <span className="card__price_text card__price_text--before">{row.description}</span>
                                                    <h1 className="h1-1-price h1-3-w-price price">
                                                        <span className="sp-1">{row.price} Bs.</span>
                                                    </h1>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                )
                            }) : <></>}
                        </div>
                    </div>
                </Box>
            </div>
            <Drawer anchor='bottom' open={drawerIsOpen} onClose={_ => { }}>
                <div className="product-view-container" style={{ height: window.screen.height }}>
                    <header ref={divHeader}>
                        <CustomHeader
                            handleClose={handleDrawerClose}
                            noButtons={true}
                        />
                    </header>
                    <div className="detail-product-container">
                        <Box>
                            <div className="selected-product-title-container">
                                <h2 className="xa _a md:ec">{product && product.name}</h2>
                                <p className="ya bb">{product && product.description}</p>
                            </div>
                            <div className="card__img_detail">
                                <img src={product && product.img} alt="" />
                                <div className="button-action-product">
                                    <Fab className="card__icon rem" size="small" aria-label="rem" sx={{
                                        backgroundColor: '#3b82f6', '&:hover': {
                                            background: "#2563eb"
                                        }, color: '#ffffff', position: ""
                                    }} onClick={_ => handleAddOrRem("rem")}>
                                        <RemoveIcon />
                                    </Fab>
                                    <h1 className="cant">
                                        <p className="cant-product-text">{productQuantity}</p>
                                    </h1>
                                    <Fab className="card__icon add" size="small" aria-label="add" sx={{
                                        backgroundColor: '#3b82f6', '&:hover': {
                                            background: "#2563eb",
                                        }, color: '#ffffff', position: ""
                                    }} onClick={_ => handleAddOrRem("add")}>
                                        <AddIcon />
                                    </Fab>
                                </div>
                            </div>
                            <div className="price-product-container">
                                <h1 className="h1-1 h1-3-w price">
                                    <span className="sp-1">{(productQuantity * (product && product.price)).toFixed(2)} Bs.</span>
                                </h1>
                            </div>
                            <div className="j y ea select-product-button">
                                <button className="btn-1" onClick={handleSetProduct}>
                                    {locates.select_a_product_button}
                                </button>
                            </div>
                        </Box>
                    </div>
                </div>
            </Drawer>
            <footer className="a ra g h footer">
                <div className="ja za ua bb terms">
                    <p>{locates.main_copyright_text}</p>
                </div>
            </footer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}

export default SelectProduct;