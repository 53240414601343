import { createSlice } from '@reduxjs/toolkit'
export const products = createSlice({
    name: 'products',
    initialState: {
      value: null,
    },
    reducers: {
      set: (state, action) => {
        state.value = action.payload;
      },
      clear: (state) => {
        state.value = null;
      }
    },
  })
  
  export const { set, clear } = products.actions
  
  export default products.reducer