import React, { useState } from 'react';
import './style.css';
/**
 * Assets
 */
import logo from '../../assets/images/logo.png';

/**
 * MUI
 */
 import IconButton from '@mui/material/IconButton';
 import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
 import CloseIcon from '@mui/icons-material/Close';

function CustomHeader({step, handleBack, handleClose, noButtons}) {

    function renderIconButton(){
        if(step > 0 && step < 6){
            return <ArrowBackIosNewIcon sx={{ color: 'black' }}/>;
        }else if(step === 6){
            return <CloseIcon sx={{ color: 'black' }}/>
        }else {
            return <CloseIcon sx={{ color: 'black' }}/>
        }
    }

    function handleIconButton(){
        if(step > 0 && step < 6){
            handleBack();
        }else if(step === 6){
            handleClose();
        }else {
            handleClose();
        }
    }

    return (
        <>
            <div className="header-cont">
                {noButtons === true ? <IconButton aria-label="back" sx={{ position:'absolute', float:'left', alignSelf:'center' }} onClick={handleIconButton}>
                    {renderIconButton()}
                </IconButton> : <></>}
                <a href="/" className="icon-logo">
                    <img src={logo} alt="logo" className="logo-header"/>
                </a>
            </div>
        </>
    );
}

export default CustomHeader;
