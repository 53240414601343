const rapidaguaUrl = ['https://rapidagua-api-dev.rapidaguave.workers.dev','https://rapidagua-api.rapidaguave.workers.dev','http://localhost:4321'];
const ENV = 1;

async function fetchHTTP_GET(path, headers) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 3500);
    const init = {
        method: "GET",
        headers: headers,
        signal: controller.signal
    };
    const response = await fetch(`${rapidaguaUrl[ENV]}${path}`, init);
    clearTimeout(id);
    return response;
};

async function fetchHTTP_POST(body, query) {
    const bodyData = JSON.stringify(body);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 3500);
    const init = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        signal: controller.signal,
        body: bodyData
    };
    const response = await fetch(`${rapidaguaUrl[ENV]}${query}`, init);
    clearTimeout(id);
    return response;
};

//////////////////////////////////////////////////////////////////////////////////////////////////
export async function getCities_API() {
    try {
        const path = "/cities/";
        const headers = {};
        const request = await fetchHTTP_GET(path, headers);
        const data = await request.json();
        return data.data.cities;
    } catch (error) {
        return null;
    }
};
//////////////////////////////////////////////////////////////////////////////////////////////////
export async function getProductsForCity_API(city) {
    try {
        const path = "/city/products/";
        const headers = {
            'city':city
        };
        const request = await fetchHTTP_GET(path, headers);
        const data = await request.json();
        return data.data.products;
    } catch (error) {
        return null;
    }
};
//////////////////////////////////////////////////////////////////////////////////////////////////
export async function getOfficesForCity_API(city) {
    try {
        const path = "/city/offices/";
        const headers = {
            'city':city
        };
        const request = await fetchHTTP_GET(path, headers);
        const data = await request.json();
        return data.data.offices;
    } catch (error) {
        return null;
    }
};

