import React, { useRef, useState, useEffect } from 'react';
import './style.css';
/**
 * Strings
 */
import locates from '../../assets/locates/es.json';
/*
* Reducers
*/
import { useSelector, useDispatch } from 'react-redux';
import { set as setProduct} from '../../redux/product';
import { set as setOffices} from '../../redux/offices';
/**
* MUI
*/
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RoomIcon from '@mui/icons-material/Room';
import StorefrontIcon from '@mui/icons-material/Storefront';

/**
 * Components
 */
import CustomHeader from '../../components/custom-header';
import CustomDialog from '../../components/custom-dialog';
/**
 * Router
 */
import { useNavigate } from "react-router-dom";
/**
 * API
 */
import { getOfficesForCity_API } from '../../api/index';

function SelectModality() {
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [toggleBackdrop, setToggleBackdrop] = useState(false);
    /**
     * Modal
     */
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const product = useSelector((state) => state.product.value);
    const cityOffices = useSelector((state) => state.city.value);

    //Header div
    const divHeader = useRef();

    function handleClose() {
        navigate("/");
    };

    async function handleSelectModality(event, type) {
        event.preventDefault();
        dispatch(setProduct({...product, modality:type}));
        setToggleBackdrop(true);
        if (type === 0){
            
        } else {
            const offices = await getOfficesForCity_API(cityOffices.id);
            setToggleBackdrop(false);
            if (offices){
                dispatch(setOffices(offices));
                navigate("/modality/pickup/");
            } else {
                setAlert(true);
                setAlertMessage("Error al cargar las sucursales");
            }
            
        }
    };
    /**
     * UseEffect
     */
    useEffect(() => {
        if (product === null) {
            navigate("/");
        };
    }, []);



    return (
        <div className="select-modality-container">
            <header ref={divHeader}>
                <CustomHeader
                    handleClose={handleClose}
                    noButtons={true}
                />
            </header>
            <div className="select-modalities-container">
                <Box>
                    <div className="select-modality-title-container">
                        <h2 className="xa _a md:ec">{locates.select_a_modality_title_one}</h2>
                        <p className="ya bb">{locates.select_a_modality_subtitle_one}</p>
                    </div>
                    <div className="j y ea select-modality-button">
                        <button className="btn-1-modality" onClick={e => handleSelectModality(e,0)}>
                            <RoomIcon sx={{ width: '30px', height: '30px', position: 'absolute' }}/>
                            <span className="text-modality">{locates.select_a_modality_button_one}</span>
                        </button>
                    </div>
                    <div className="j y ea select-modality-button">
                        <button className="btn-1-modality" onClick={e => handleSelectModality(e,1)}>
                            <StorefrontIcon sx={{ width: '30px', height: '30px', position: 'absolute' }}/>
                            <span className="text-modality">{locates.select_a_modality_button_two}</span>
                        </button>
                    </div>
                </Box>
            </div>
            <footer className="a ra g h footer">
                <div className="ja za ua bb terms">
                    <p>{locates.main_copyright_text}</p>
                </div>
            </footer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomDialog
                message={alertMessage}
                open={alert}
                handleClose={_ => setAlert(false)}
            />
        </div>
    )
}

export default SelectModality;