import React, { useState, useEffect } from 'react';
import './style.css';
/**
 * Strings
 */
import locates from '../../assets/locates/es.json';
/**
 * Assets
 */
import logo from '../../assets/images/logo.png';
import hand_illustration from '../../assets/images/hand-illustration.png';
/**
 * Router
 */
/**
 * MUI
 */
import { Backdrop, CircularProgress } from '@mui/material';
/**
 * Icons
 */
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PlaceIcon from '@mui/icons-material/Place';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
/**
 * Components
 */
import CustomDialog from '../../components/custom-dialog';
/**
 * API
 */
import { getCities_API } from '../../api/index';
/**
 * Router
 */
import { useNavigate } from "react-router-dom";
/**
 *  Reducers
 */
import { set } from '../../redux/cities';
import { useSelector, useDispatch } from 'react-redux';

function Home() {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [toggleBackdrop, setToggleBackdrop] = useState(false);
    /**
     * Modal
     */
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    function handleRRSSIG(event) {
        event.preventDefault();
        navigate("/");
    };
    function handleRRSSFB(event) {
        event.preventDefault();
        navigate("/");
    };
    function handleRRSSLK(event) {
        event.preventDefault();
        navigate("/");
    };
    function handleRRSSX(event) {
        event.preventDefault();
        navigate("/");
    };

    async function getCities(event) {
        event.preventDefault();
        setToggleBackdrop(true);
        const cities = await getCities_API();
        setToggleBackdrop(false);
        if (cities) {
            dispatch(set(cities));
            navigate("/cities");
        } else {
            setAlert(true);
            setAlertMessage("Error al cargar las ciudades");
        }
    };

    /**
     * UseEffect
     */
    useEffect(() => {

    }, []);

    return (
        <main>
            <div className="c la xl:nc pa">
                <div className="a ra g sm:rb xl:oc">
                    <div className="b d">
                        <a href="/#" aria-label="" title="Logo">
                            <img src={logo} alt="" className="logo-img"/>
                        </a>
                    </div>
                    <div className="k w i xl:oc ca sm:ub sm:vb sm:tb xl:mc">
                        <div className="da sm:pb md:ha">
                            <h1 className="h1-1 h1-3-w">
                                <span className="sp-1">{locates.process_title}</span>
                                <span className="sp-1 text-1">{locates.process_subtitle}</span>
                            </h1>
                            <p className="mt-3">
                                {locates.process_description}
                            </p>
                            <div className="j y ea">
                                <button className="btn-1" onClick={getCities}>
                                    {locates.start_button}
                                </button>
                            </div>
                        </div>
                        <div className="k _">
                            <img className="e o sm:b sm:ob md:yb lg:fc sm:sb lg:hc" src={hand_illustration} alt="" />
                        </div>
                    </div>
                </div>
                <div className="l sm:qb sm:b f sm:nb">
                    <svg xmlns="http://www.w3.org/2000/svg" className="p m v" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                    </svg>
                </div>
            </div>
            <section className="a ra sa g lg:jc">
                <div className="k aa md:ba lg:ic">
                    <div className="">
                        <h2 className="xa _a md:ec">{locates.main_principal_text_title}</h2>
                    </div>
                    <div className="lg:gc">
                        <p className="wa md:xa">{locates.main_principal_text_subtitle}</p>
                    </div>
                </div>
            </section>
            <div className="a g na oa sm:wb"></div>
            <section className="a ra sa g lg:jc">
                <div className="k ba xl:ic">
                    <div className="">
                        <h2 className="xa _a md:ec">{locates.main_feature_text_title}</h2>
                    </div>
                    <div className="k xl:gc ca sm:ba sm:tb">
                        <div className="da">
                            <div className="j x ga">
                                <div className="j x z q n ab qa ma">
                                    <PlaceIcon />
                                </div>
                                <h3 className="wa _a">{"Selecciona tu localidad"}</h3>
                            </div>
                            <p className="t ya bb lg:lc">{"Selecciona tu ubicación exacta, para que nuestros motorizados puedan ubicarte y dirigirse al momento realizar la recarga de los litros."}</p>
                        </div>
                        <div className="da">
                            <div className="j x ga">
                                <div className="j x z q n ab qa ma">
                                    <ShoppingCartIcon />
                                </div>
                                <h3 className="wa _a">{locates.main_feature_text_two_title}</h3>
                            </div>
                            <p className="t ya bb lg:lc">{locates.main_feature_text_two_subtitle}</p>
                        </div>
                        <div className="da">
                            <div className="j x ga">
                                <div className="j x z q n ab qa ma">
                                    <PriceChangeIcon />
                                </div>
                                <h3 className="wa _a">{locates.main_feature_text_three_title}</h3>
                            </div>
                            <p className="t ya bb lg:lc">{locates.main_feature_text_three_subtitle}</p>
                        </div>
                        <div className="da">
                            <div className="j x ga">
                                <div className="j x z q n ab qa ma">
                                    <DeliveryDiningIcon />
                                </div>
                                <h3 className="wa _a">{locates.main_feature_text_four_title}</h3>
                            </div>
                            <p className="t ya bb lg:lc">{locates.main_feature_text_four_subtitle}</p>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="a ra g h">
                <div className="ja za ua bb md:j md:_b md:x md:bc">
                    <div className="ka md:bc md:j md:_b md:x md:cc">
                        <p>{locates.main_copyright_text}</p>
                        <p>
                            <a className="cb db" href="/terms" rel="nofollow" aria-label="" title="Términos y Condiciones">{locates.main_terms_and_conditions_text}</a>
                        </p>
                    </div>
                    <div className="j z ea">
                        <a href="/#" aria-label="" title="Instagram">
                            <InstagramIcon />
                        </a>
                        <a href="/#" aria-label="" title="Facebook">
                            <FacebookIcon />
                        </a>
                        <a href="/#" aria-label="" title="Linkedin">
                            <LinkedInIcon />
                        </a>
                        <a href="/#" aria-label="" title="Twitter">
                            <TwitterIcon />
                        </a>
                    </div>
                </div>
            </footer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomDialog
                message={alertMessage}
                open={alert}
                handleClose={_ => setAlert(false) }
            />
        </main>
    );
}

export default Home;
