import { createSlice } from '@reduxjs/toolkit'
export const cities = createSlice({
    name: 'cities',
    initialState: {
      value: null,
    },
    reducers: {
      set: (state, action) => {
        state.value = action.payload;
      },
      clear: (state) => {
        state.value = [{name:"",id:""}]
      }
    },
  })
  
  export const { set, clear } = cities.actions
  
  export default cities.reducer