import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './pages/home';
import Terms from './pages/terms';
import SelectCity from './pages/select-city';
import SelectModality from './pages/select-modality';
import PickUpModality from './pages/pick-up-modality';

import SelectProduct from './pages/select-product';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './redux/store';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <p>Error 404</p>,
  },
  {
    path: "*",
    element: <Home />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/cities",
    element: <SelectCity />,
  },
  {
    path: "/products",
    element: <SelectProduct />,
  },
  {
    path: "/modality",
    element: <SelectModality />,
  },
  {
    path: "/modality/pickup/",
    element: <PickUpModality />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
