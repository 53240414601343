import { configureStore } from '@reduxjs/toolkit';
import cities from './cities';
import city from './city';
import products from './products';
import product from './product';
import offices from './offices';

export default configureStore({
  reducer: {
    cities:cities,
    city:city,
    products:products,
    product:product,
    offices:offices
  },
})