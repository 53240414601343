import React, { useRef, useState, useEffect } from 'react';
import './style.css';
/**
 * Strings
 */
import locates from '../../assets/locates/es.json';
/*
* Reducers
*/
import { useSelector, useDispatch } from 'react-redux';
import { set } from '../../redux/product';
/**
* MUI
*/
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RoomIcon from '@mui/icons-material/Room';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Button, CardActions } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';

/**
 * Components
 */
import CustomHeader from '../../components/custom-header';
import CustomDialog from '../../components/custom-dialog';
/**
 * Router
 */
import { useNavigate } from "react-router-dom";
/**
 * API
 */
import { getProductsForCity_API } from '../../api/index';

function PickUpModality() {
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [toggleBackdrop, setToggleBackdrop] = useState(false);
    /**
     * Modal
     */
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const product = useSelector((state) => state.product.value);
    const city = useSelector((state) => state.city.value);
    const offices = useSelector((state) => state.offices.value);
    const [office, setOffice] = useState(null);

    //Header div
    const divHeader = useRef();

    function handleClose() {
        navigate("/");
    };

    function handleSelectOffice(event, office) {
        event.preventDefault();
        dispatch(set({ ...product, office: office }));
        setToggleBackdrop(true);
        setTimeout(() => {
            setToggleBackdrop(false);
            //navigate("/");
        }, 1000);

    };

    function handleDrawerClose() {
        setDrawerIsOpen(false);
    };

    /**
     * UseEffect
     */
    useEffect(() => {
        if (offices === null) {
            navigate("/");
        };
    }, []);



    return (
        <div className="pick-up-modality-container">
            <header ref={divHeader}>
                <CustomHeader
                    handleClose={handleClose}
                    noButtons={true}
                />
            </header>
            <div className="pick-up-modalities-container">
                <Box>
                    <div className="pick-up-modality-title-container">
                        <h2 className="xa _a md:ec">{locates.modality_pickup_title_one}</h2>
                        <p className="ya bb">{locates.modality_pickup_subtitle_one}</p>
                    </div>
                    <div className="cards-container">
                        <div className="cards">
                            {offices ? offices.map((row, i) => {
                                return (
                                    <Card sx={{}} key={i} onClick={_ => { setDrawerIsOpen(true); setOffice(row); }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="img"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div" sx={{display:'flex'}}>
                                                    <div className="office-status-title">{row.name}</div>
                                                    <div className="office-status-icon">
                                                        <Tooltip title={row.open === true ?  "Abierto" : "Cerrado"}>
                                                            <FiberManualRecordIcon sx={{width:'20px', height:'20px', color:row.open === true ? '#02ba05' : '#d90214'}}/>
                                                        </Tooltip>
                                                    </div>
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {row.address}
                                                </Typography>
                                                <CardActions>
                                                    </CardActions>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                )
                            }) : <></>}
                        </div>
                    </div>
                </Box>
                <Drawer anchor='bottom' open={drawerIsOpen} onClose={_ => { }}>
                    <div className="office-view-container" style={{ height: window.screen.height }}>
                        <header ref={divHeader}>
                            <CustomHeader
                                handleClose={handleDrawerClose}
                                noButtons={true}
                            />
                        </header>
                        <div className="detail-office-container">
                            <Box>
                                <div className="selected-office-title-container">
                                    <h2 className="xa _a md:ec">{office && office.name}</h2>
                                    <p className="ya bb">{office && office.address}</p>
                                </div>
                                <div className="office__img_detail-container">
                                    <img className="office__img_detail" src={office && office.img} alt="office" />
                                </div>
                            </Box>
                        </div>
                    </div>
                </Drawer>
            </div>
            <footer className="a ra g h footer">
                <div className="ja za ua bb terms">
                    <p>{locates.main_copyright_text}</p>
                </div>
            </footer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomDialog
                message={alertMessage}
                open={alert}
                handleClose={_ => setAlert(false)}
            />
        </div>
    )
}

export default PickUpModality;