import React, { forwardRef } from 'react';
/**
 * MUI
 */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomDialog({open, handleClose, message}) {

    function handleCloseDialog(event) {
        event.preventDefault();
        handleClose(false);
    };

    function getErrorMessage(){
        return message;
    };

    function getTitleErrorMessage(){
        return "Alerta";  
    };

    return (
        <div>
            <Dialog
                open={open || false}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{getTitleErrorMessage()}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {getErrorMessage()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Aceptar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CustomDialog;