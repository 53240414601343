import React, { useRef } from 'react';
/**
 * Strings
 */
import locates from '../../assets/locates/es.json';
/**
* Icons
*/
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
/**
 * Components
 */
import CustomHeader from '../../components/custom-header';
/**
* Router
*/
//import { useHistory } from "react-router-dom";

function Terms() {

    //Header div
    const divHeader = useRef();
    //const history = useHistory();

    function handleClose() {
        //history.push('/');
    }

    return (
        <>
            <header ref={divHeader}>
                <CustomHeader
                    handleClose={handleClose}
                    noButtons={true}
                />
            </header>
            <body>
                <p>terminos y condiciones</p>
            </body>
            <footer className="a ra g h">
                <div className="ja za ua bb md:j md:_b md:x md:bc">
                    <div className="ka md:bc md:j md:_b md:x md:cc">
                        <p>{locates.main_copyright_text}</p>
                    </div>
                    <div className="j z ea">
                        <a href="/" aria-label="" title="Instagram">
                            <InstagramIcon />
                        </a>
                        <a href="/" aria-label="" title="Facebook">
                            <FacebookIcon />
                        </a>
                        <a href="/" aria-label="" title="Linkedin">
                            <LinkedInIcon />
                        </a>
                        <a href="/" aria-label="" title="Twitter">
                            <TwitterIcon />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Terms;