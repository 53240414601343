import React, { useRef, useState, useEffect } from 'react';
import './style.css';
/**
 * Strings
 */
import locates from '../../assets/locates/es.json';
/*
* Reducers
*/
import { useSelector, useDispatch } from 'react-redux';
import { set as setCity } from '../../redux/city';
import { set as setProducts } from '../../redux/products';
/**
* MUI
*/
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * Components
 */
import CustomHeader from '../../components/custom-header';
import CustomDialog from '../../components/custom-dialog';
/**
 * Router
 */
import { useNavigate } from "react-router-dom";
/**
 * API
 */
import { getProductsForCity_API } from '../../api/index';


function SelectCity() {
    const [toggleDrawer, setToggleDrawer] = useState(false);
    const [toggleBackdrop, setToggleBackdrop] = useState(false);
    /**
     * Modal
     */
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const cities = useSelector((state) => state.cities.value);
    const [idCity, setIdCity] = useState('');

    //Header div
    const divHeader = useRef();

    function handleClose() {
        navigate("/");
    };



    async function getProductsFromCity(event) {
        event.preventDefault();
        setIdCity(event.target.value);
        setToggleBackdrop(true);
        const products = await getProductsForCity_API(event.target.value.id);
        setToggleBackdrop(false);
        if (products) {
            dispatch(setCity(event.target.value));
            dispatch(setProducts(products));
            navigate("/products");
        } else {
            setAlert(true);
            setAlertMessage("Error al cargar los productos");
        }
    };

    /**
     * UseEffect
     */
        useEffect(() => {
            if(cities === null){
                navigate("/");
            };
        }, []);



    return (
        <div className="select-city-container">
            <header ref={divHeader}>
                <CustomHeader
                    handleClose={handleClose}
                    noButtons={true}
                />
            </header>
            <div className="select-cities-container">
                <Box>
                    <div className="select-city-title-container">
                        <h2 className="xa _a md:ec">{locates.select_a_city}</h2>
                        <p className="ya bb">{locates.select_a_city_subtitle}</p>
                    </div>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">{locates.text_city}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={idCity}
                            label={locates.text_city}
                            onChange={getProductsFromCity}
                        >
                            {
                                cities ? cities.map((city, i) => {
                                    return (<MenuItem key={i} value={city}>{city.name}</MenuItem>)
                                }) : <MenuItem key={''} value=""></MenuItem>
                            }
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <footer className="a ra g h footer">
                <div className="ja za ua bb terms">
                    <p>{locates.main_copyright_text}</p>
                </div>
            </footer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={toggleBackdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <CustomDialog
                message={alertMessage}
                open={alert}
                handleClose={_ => setAlert(false) }
            />
        </div>
    )
}

export default SelectCity;